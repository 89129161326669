import React from 'react';

export const IconPhoto: React.FC = (): JSX.Element => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.1132 14.3181C3.80336 14.3181 2.63824 13.4795 2.21516 12.2309L2.18655 12.1368C2.08677 11.8062 2.04497 11.5281 2.04497 11.2499V5.67138L0.0600688 12.2971C-0.195217 13.2717 0.386517 14.282 1.36256 14.5512L14.0143 17.9395C14.1722 17.9804 14.3301 18 14.4857 18C15.3005 18 16.0451 17.4592 16.2538 16.6621L16.9909 14.3181H5.1132Z" fill="#FFB801"/>
      <path d="M7.36305 5.72747C8.26554 5.72747 8.99934 4.99353 8.99934 4.09104C8.99934 3.18855 8.26554 2.4546 7.36305 2.4546C6.46056 2.4546 5.72662 3.18855 5.72662 4.09104C5.72662 4.99353 6.46056 5.72747 7.36305 5.72747Z" fill="#FFB801"/>
      <path d="M17.5903 0H5.3174C4.19004 0 3.27197 0.918069 3.27197 2.04558V11.0456C3.27197 12.1731 4.19004 13.0912 5.3174 13.0912H17.5903C18.7178 13.0912 19.6359 12.1731 19.6359 11.0456V2.04558C19.6359 0.918069 18.7178 0 17.5903 0ZM5.3174 1.63643H17.5903C17.8162 1.63643 17.9994 1.81966 17.9994 2.04558V7.85393L15.4148 4.83799C15.1406 4.51649 14.7438 4.34465 14.3176 4.33491C13.8937 4.33731 13.4961 4.52548 13.2245 4.85118L10.1856 8.49858L9.19567 7.511C8.63611 6.95144 7.72538 6.95144 7.16657 7.511L4.9084 9.76842V2.04558C4.9084 1.81966 5.09162 1.63643 5.3174 1.63643Z" fill="#FFB801"/>
    </svg>
  );
};
