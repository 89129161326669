export const colorSchema = {
  blue: '#3D4F87',
  yellow:'#FFB801',
  red:'#EB5757',
  darkwhite:'#F8F8F8',
  darkgray:'#272727',
  gray:'#666666',
  grey:'#ddd',
  black:'#333333',
  lightgray:'#DBD6D0',
  lightgray2:'#F4F3F1',
  green:'#14CCAE'
}
