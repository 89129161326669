import React from 'react';
import {
  StyledAccentText,
  StyledParagraph,
  StyledPrivacyPolicy,
  StyledTextSection,
  StyledTextSubTitle,
  StyledTextTitle,
  StyledTextWrapper
} from './PrivacyPolicy.styled';
import { TitleBanner } from '../../components/TitleBanner';
import { ContentWrapper } from '../../components/ContentWrapper';
import { theme } from '../../theme';

export const PrivacyPolicy: React.FC = () => {
  return (
    <StyledPrivacyPolicy>
      <TitleBanner>Соглашение на обработку персональных данных</TitleBanner>
      <ContentWrapper>
        <StyledTextWrapper>
          <StyledTextSection>
            <StyledParagraph>
              Настоящим в соответствии с Федеральным законом № 152-ФЗ «О персональных данных» от 27.07.2006 года Вы
              подтверждаете свое согласие на обработку компанией {`<КОМПАНИЯ>`} персональных данных: сбор,
              систематизацию,
              накопление, хранение, уточнение (обновление, изменение), использование, передачу исключительно в целях
              продажи
              программного обеспечения на Ваше имя, как это описано ниже, блокирование, обезличивание, уничтожение.
            </StyledParagraph>
            <StyledParagraph>
              Компания {`<КОМПАНИЯ>`} гарантирует конфиденциальность получаемой информации. Обработка персональных
              данных
              осуществляется в целях эффективного исполнения заказов, договоров и иных обязательств, принятых
              компанией {`<КОМПАНИЯ>`} в качестве обязательных к исполнению.
            </StyledParagraph>
            <StyledParagraph>
              В случае необходимости предоставления Ваших персональных данных правообладателю, дистрибьютору или
              реселлеру
              программного обеспечения в целях регистрации программного обеспечения на ваше имя, вы даёте согласие на
              передачу ваших персональных данных. Компания {`<КОМПАНИЯ>`} гарантирует, что правообладатель, дистрибьютор
              или
              реселлер программного обеспечения осуществляет защиту персональных данных на условиях, аналогичных
              изложенным в Политике конфиденциальности персональных данных.
            </StyledParagraph>
          </StyledTextSection>
          <StyledTextSection>
            <StyledTextTitle>
              Площадка для сотрудничества в области туризма и образования
            </StyledTextTitle>
            <StyledParagraph>
              Настоящее согласие распространяется на следующие Ваши персональные данные: фамилия, имя и отчество, адрес
              электронной почты, почтовый адрес доставки заказов, контактный телефон, платёжные реквизиты.
            </StyledParagraph>
            <StyledParagraph>
              Срок действия согласия является неограниченным. Вы можете в любой момент отозвать настоящее согласие,
              направив письменное уведомления на адрес: 000000, г. {`<ГОРОД>`}, ул. {`<УЛИЦА>`}, д. {`<ДОМ>`},
              офис {`<ОФИС>`} с пометкой
              «Отзыв согласия на обработку персональных данных».
            </StyledParagraph>
            <StyledParagraph>
              Обращаем ваше внимание, что отзыв согласия на обработку персональных данных влечёт за собой удаление Вашей
              учётной записи с Интернет-сайта (http://вашсайт.ru), а также уничтожение
              записей,<StyledAccentText color={theme.color.blue}> содержащих ваши
              персональные данные</StyledAccentText>, в системах обработки персональных данных компании {`<КОМПАНИЯ>`},
              что может сделать
              невозможным пользование интернет-сервисами компании {`<КОМПАНИЯ>`}.
            </StyledParagraph>
          </StyledTextSection>

          <StyledTextSection>
            <StyledTextSubTitle>
              Площадка для сотрудничества в области туризма и образования
            </StyledTextSubTitle>
            <StyledParagraph>
              Гарантирую, что представленная мной информация является полной, точной и <StyledAccentText color={theme.color.yellow}>достоверной</StyledAccentText>, а также что при
              представлении информации не нарушаются действующее законодательство Российской Федерации, законные права и
              интересы третьих лиц. Вся представленная информация заполнена мною в отношении себя лично.
            </StyledParagraph>
            <StyledParagraph>
              Настоящее согласие действует в течение всего периода хранения персональных данных, если иное не
              предусмотрено законодательством Российской Федерации.
            </StyledParagraph>
          </StyledTextSection>
        </StyledTextWrapper>
      </ContentWrapper>
    </StyledPrivacyPolicy>
  );
};
