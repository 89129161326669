import React from 'react';

export const IconLock: React.FC = (): JSX.Element => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 8 12" fill="none">
      <path
        d="M2.57974 5.52712H1.63237V3.1587C1.63237 1.85066 2.69275 0.790283 4.00079 0.790283C5.30883 0.790283 6.36921 1.85066 6.36921 3.1587V4.10607H5.42184V3.1587C5.42184 2.37388 4.78561 1.73765 4.00079 1.73765C3.21596 1.73765 2.57974 2.37388 2.57974 3.1587V5.52712ZM6.84288 5.52791H1.15867C0.635455 5.52791 0.211304 5.95206 0.211304 6.47527V10.2647C0.211304 10.788 0.635455 11.2121 1.15867 11.2121H6.84288C7.3661 11.2121 7.79025 10.788 7.79025 10.2647V6.47527C7.79025 5.95206 7.3661 5.52791 6.84288 5.52791ZM1.1579 10.264V6.4745H6.84211V10.264H1.1579ZM4.47527 8.36921C4.47527 8.63082 4.2632 8.84289 4.00159 8.84289C3.73998 8.84289 3.5279 8.63082 3.5279 8.36921C3.5279 8.1076 3.73998 7.89553 4.00159 7.89553C4.2632 7.89553 4.47527 8.1076 4.47527 8.36921Z"
        fill="#3D4F87"/>
    </svg>
  );
};
