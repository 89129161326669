import React from 'react';

export const IconKey: React.FC = (): JSX.Element => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 10 10" fill="none">
      <path
        d="M3.10446 3.57824C3.10446 1.74698 4.58899 0.262451 6.42025 0.262451C8.25151 0.262451 9.73604 1.74698 9.73604 3.57824C9.73604 5.4095 8.25151 6.89403 6.42025 6.89403H5.47288V7.8414H4.52552V8.78876H3.57815V9.73613H0.26236V7.17151L3.17457 4.25929C3.12813 4.03699 3.10446 3.80907 3.10446 3.57824ZM4.52474 5.94589H6.41948C7.72752 5.94589 8.7879 4.88551 8.7879 3.57747C8.7879 2.26943 7.72752 1.20905 6.41948 1.20905C5.11144 1.20905 4.05106 2.26943 4.05106 3.57747C4.05106 3.81366 4.08549 4.0447 4.15247 4.26567L4.2348 4.53731L1.20896 7.56315V8.78799H2.63001V7.84062H3.57738V6.89326H4.52474V5.94589ZM5.47207 3.57739C5.47207 4.10061 5.89622 4.52476 6.41944 4.52476C6.94265 4.52476 7.3668 4.10061 7.3668 3.57739C7.3668 3.05418 6.94265 2.63003 6.41944 2.63003C5.89622 2.63003 5.47207 3.05418 5.47207 3.57739Z"
        fill="#FFB801"/>
    </svg>

  );
};
