export const regexps = {

  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  password: /(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])[0-9a-zA-Z.!#$%&'*+/=?^_`{|}~-]{8,255}$/,
  website:
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[\u00C0-\u017Fa-z0-9]+([-.]{1}[\u00C0-\u017Fa-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
  userName: /^(?=.{4,30}$)(?![_])(?!.*[_]{})[a-zA-Z0-9_]+()$/, // +(?<![_]) проверка на запрет окончания фразы символом _ нельзя ставить, т.к. падает safari
  spaceTrimmer: /\s+/g,
};
