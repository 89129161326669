export const FETCH_PUBLICATIONS_REQUEST = 'FETCH_PUBLICATIONS_REQUEST';
export const FETCH_PUBLICATIONS_SUCCESS = 'FETCH_PUBLICATIONS_SUCCESS';
export const FETCH_PUBLICATIONS_FAILURE = 'FETCH_PUBLICATIONS_FAILURE';

export const FETCH_PUBLICATION_REQUEST = 'FETCH_PUBLICATION_REQUEST';
export const FETCH_PUBLICATION_SUCCESS = 'FETCH_PUBLICATION_SUCCESS';
export const FETCH_PUBLICATION_FAILURE = 'FETCH_PUBLICATION_FAILURE';

export const FETCH_CATEGORIES_REQUEST = 'FETCH_CATEGORIES_REQUEST';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';

export const POST_PUBLICATION_REQUEST = 'POST_PUBLICATION_REQUEST';
export const POST_PUBLICATION_SUCCESS = 'POST_PUBLICATION_SUCCESS';
export const POST_PUBLICATION_FAILURE = 'POST_PUBLICATION_FAILURE';

export const UPDATE_PUBLICATION_REQUEST = 'UPDATE_PUBLICATION_REQUEST';
export const UPDATE_PUBLICATION_SUCCESS = 'UPDATE_PUBLICATION_SUCCESS';
export const UPDATE_PUBLICATION_FAILURE = 'UPDATE_PUBLICATION_FAILURE';

export const DELETE_PUBLICATION_REQUEST = 'DELETE_PUBLICATION_REQUEST';
export const DELETE_PUBLICATION_SUCCESS = 'DELETE_PUBLICATION_SUCCESS';
export const DELETE_PUBLICATION_FAILURE = 'DELETE_PUBLICATION_FAILURE';

export const REMOVE_PUBLICATION_IMG = 'REMOVE_PUBLICATION_IMG';