export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const VERIFY_USER_REQUEST = 'VERIFY_USER_REQUEST';
export const VERIFY_USER_SUCCESS = 'VERIFY_USER_SUCCESS';
export const VERIFY_USER_FAILURE = 'VERIFY_USER_FAILURE';

export const RESEND_VERIFY_USER_REQUEST = 'RESEND_VERIFY_USER_REQUEST';
export const RESEND_VERIFY_USER_SUCCESS = 'RESEND_VERIFY_USER_SUCCESS';
export const RESEND_VERIFY_USER_FAILURE = 'RESEND_VERIFY_USER_FAILURE';

export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';

export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

export const LOGOUT_USER_REQUEST = 'LOGOUT_USER_REQUEST';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAILURE = 'LOGOUT_USER_FAILURE';

export const CHECK_AUTH_USER_REQUEST = 'CHECK_AUTH_USER_REQUEST';
export const CHECK_AUTH_USER_SUCCESS = 'CHECK_AUTH_USER_SUCCESS';
export const CHECK_AUTH_USER_FAILURE = 'CHECK_AUTH_USER_FAILURE';

export const RESET_USER_PASSWORD_REQUEST = 'RESET_USER_PASSWORD_REQUEST';
export const RESET_USER_PASSWORD_SUCCESS = 'RESET_USER_PASSWORD_SUCCESS';
export const RESET_USER_PASSWORD_FAILURE = 'RESET_USER_PASSWORD_FAILURE';

export const SET_FILE_UPLOAD_STATUS = 'SET_FILE_UPLOAD_STATUS';


export const REMOVE_USER_ERRORS = 'REMOVE_USER_ERRORS';
